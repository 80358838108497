import { Box, Button, Divider, Grid, Hidden, Icon, IconButton, Modal, Notification, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import ClaimConditions from "components/advance/ClaimConditions"
import ClaimRemarks from "components/advance/ClaimRemarks"
import { RequiredDocumentCard } from "components/advance/RequiredDocumentCard"
import { UploadDocumentCard } from "components/advance/UploadDocumentCard"

import { INPUT_TYPE } from "constants/enums/input-type"
import { OptionValue } from "constants/enums/option-value"
import dayjs from "dayjs"
import { compose, withConfirmGoBack, withConfirmRefresh, withFormik, withHooks, withStores } from "enhancers"

import { Card, CardContent } from "@material-ui/core"
import { ReactComponent as Point } from "assets/icon/point.svg"
import LoadingModal from "components/LoadingModal"
import { IconName } from "components/common/Icon"
import { MuiIcon } from "components/common/MuiIcon"
import { env } from "configs"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { PageContent } from "layouts"
import { every, get, isEmpty, keyBy } from "lodash"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, gql, isJson, paths, toCurrency } from "utils/helper"
import BudgetComponent from "../claim-requests/BudgetComponent"
import SuccessPage from "./PageSuccess"
import ConsentModal from "./consent-modal"
import { Field } from "./form-field"

const CustomIconList = styled(MuiIcon)`
  font-size: 20px;
  margin-left: 8px;
`

const ContentBox = styled(Box)`
  padding: 16px;
`
const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${AppColor["System/Warning Light"]};
  border-radius: 8px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const List = styled.ul`
  margin: 0px 0px 0px -16px;
`
const Header = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  margin-bottom: 7px;
  .closeBtn {
    border: none;
    position: absolute;
    right: 0px;
  }
`
const Main = styled("div")`
  overflow-y: scroll;
  color: ${AppColor["Text/Secondary"]};
  height: 500px;
  width: 100%;
`

export interface InfoValuesConfig {
  inputs: object
  documents: object
}

const ClaimDetailPage = (props: any) => (
  <>
    <LoadingModal isOpen={!props.isLoadingConfig} />
    <LoadingModal isOpen={props.createClaimRequestLoading} title="กำลังส่งคำขอการเบิก" />
    <ConsentModal
      isOpen={props.isConsentModalOpen}
      onClose={props.handleCloseConsentModal}
      onConfirm={props.handleClickSendRequest}
      checkHaveConsent={props.checkHaveConsent}
    />
    {props.step !== 4 && props.isLoadingConfig && (
      <PageContent
        title={props.titlePage}
        titleCentered
        onBack={props.handleClickBack}
        extendedTitleHeight="100px"
        overlapHeight="70px"
        type="primary"
      >
        <ContentBox>
          {props.isHr && props.isPreview && (
            <WarningBox mb={props.step === 4 ? "14px" : "16px"}>
              <Icon
                name="Warning"
                color={AppColor["Error/Error Text"]}
                width={"16px"}
                height={"16px"}
                style={{ marginRight: "4px" }}
              ></Icon>
              <Typography variant="body2" color={AppColor["Text/Primary Text"]}>
                กำลังทำรายการแทนพนักงานคนอื่น
              </Typography>
            </WarningBox>
          )}
          <ClaimCard
            title={props.title}
            type={props.type}
            name={props.name}
            approvedRequest={props.approvedCount}
            totalAmount={props.approvedAmount}
            icon={props.icon}
            iconColor={props.iconColor}
            chipTitleColor={props.chipTitleColor}
            chipBackgroundColor={props.chipBackgroundColor}
            fullSize
            displayOnly
          />
          {!props.isPreview && (
            <>
              <ClaimConditions conditions={props.conditions} />
              <RequiredDocumentCard documents={props.documents} />
              <Hidden when={props.remark?.length === 0}>
                <Divider style={{ margin: "32px 0px 4px 0px", color: AppColor["Text/Primary Text"] }} />
              </Hidden>
              <ClaimRemarks remarks={props.remark} step={props.step} />
            </>
          )}
          {props.step === 1 && (
            <Button onClick={props.handleClickClaim} width="100%" variant="outlined" style={{ marginBottom: 24 }}>
              เบิกสวัสดิการนี้
              <CustomIconList name="ChevronRight" />
            </Button>
          )}
          {props.step !== 1 && (
            <>
              {!props.isPreview && (
                <Divider style={{ margin: "32px 0px 32px 0px", background: AppColor["Primary/Line"] }} />
              )}
              <Box mt={props.getMarginTop} display="flex" alignItems="center">
                {env.ICON_CONFIG.DETAIL_LABEL && (
                  <Icon
                    style={{ marginRight: "8px" }}
                    name={env.ICON_CONFIG.DETAIL_LABEL as IconName}
                    color={AppColor["Primary/Primary Text"]}
                    width={24}
                    height={24}
                  />
                )}

                <Typography variant="h3" color="Text/Black2">
                  {props.isPreview ? "ตรวจสอบรายละเอียด" : "กรอกรายละเอียดการเบิก"}
                </Typography>
              </Box>
              {props.isHr && !props.isPreview && (
                <WarningBox mt="16px">
                  <Icon
                    style={{ marginRight: "4px" }}
                    name="Warning"
                    color={AppColor["Error/Error Text"]}
                    width={16}
                    height={16}
                  />
                  <Typography variant="body2" color={AppColor["Text/Primary Text"]}>
                    กำลังทำรายการแทนพนักงานคนอื่น
                  </Typography>
                </WarningBox>
              )}
              <Grid container spacing={0} style={{ paddingTop: 16, marginBottom: "40px" }}>
                <Grid item xs={12} key="select-employee">
                  <Box mt={props.isPreview ? "0px" : "8px"}>
                    {props.currentUserIsHr && props.title === "ค่าช่วยเหลือจัดการงานศพ (3 เท่า)" ? (
                      <Field
                        fast={false}
                        isPreview={props.isPreview}
                        component="SELECT"
                        label="ผู้เสียชีวิต"
                        placeholder="ผู้เสียชีวิต"
                        name="requesterId"
                        options={props.employeeOptions}
                        value={props.requesterFullName}
                        disabled={!props.currentUserIsHr}
                        disableClearable
                        required
                      />
                    ) : (
                      <Field
                        fast={false}
                        isPreview={props.isPreview}
                        component="SELECT"
                        label="ผู้ขอเบิก"
                        name="requesterId"
                        options={props.employeeOptions}
                        value={props.requesterFullName}
                        disabled={!props.currentUserIsHr}
                        disableClearable
                        required
                      />
                    )}
                  </Box>
                </Grid>

                {props.inputs.map((input: any) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      key={`${input.name}_k`}
                      style={{ paddingTop: input.type === INPUT_TYPE.SUM_AMOUNT ? 0 : 16 }}
                    >
                      <Box mt={props.isPreview || input.readOnly ? "0px" : "8px"}>
                        <Field
                          fast={!(input.type === INPUT_TYPE.RELATION)}
                          isPreview={props.isPreview || input.readOnly}
                          value={props.inputValues[input.name]}
                          component={input.type}
                          label={input.title}
                          name={input.name}
                          disabled={input.disabled}
                          options={input.options}
                          unit={input.unit}
                          placeholder={input.placeholder}
                          required={input.required}
                          icon={input.icon}
                          iconColor={input.iconColor}
                          helperText={input.remark}
                          master={props.master}
                          requester={props.requester}
                          auto={input.auto}
                          requestName={props.name}
                          values={props.values}
                          setFieldValue={props.setFieldValue}
                          maxDate={input.maxDate}
                          minDate={input.minDate}
                          filterOptions={input.filterOptions}
                          setAdditionalList={input.optionAddable ? props.setDiseaseList : undefined}
                          additionalList={input.optionAddable ? props.newDiseases : []}
                        />
                      </Box>
                    </Grid>
                    {props.hasIsOther(input.type, input.name) && (
                      <Grid item xs={12} key={`${input.name}_is_other`} style={{ paddingTop: 16 }}>
                        <Typography variant="body1" color={props.isPreview ? "Text/Gray Preview" : "Text/Black2"}>
                          {`กรอก ${input.title} อื่นๆ`}
                        </Typography>
                        <Box mt={props.isPreview ? "0px" : "8px"}>
                          <Field component={INPUT_TYPE.TEXT} label="" name={input.name + "_is_other"} />
                        </Box>
                      </Grid>
                    )}
                  </>
                ))}
                <BudgetComponent
                  budgetDetail={props.budgetDetail}
                  budgetValues={props.budgetValues}
                  year={props.year}
                  values={props.values}
                  yearlyBudget={props.yearlyBudget}
                  type={props.name}
                />
              </Grid>

              {props.showUploadFile && (
                <UploadDocumentCard
                  documents={props.documents}
                  values={props.documentValues}
                  onChange={props.handleFilesChange}
                  canDelete={!props.isPreview}
                  isPreview={props.isPreview}
                  onUploading={props.handleUploading}
                  onUploaded={props.handleUploaded}
                  isUploading={props.isUploading}
                />
              )}

              {props.isPreview ? (
                <>
                  {env.USER_CAN_SEE_REMAINING_BUDGET && props.amount > 0 && (
                    <div style={{ marginBottom: "40px" }}>
                      <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "16px" }}>
                        <Point></Point>
                        <Typography variant="h3" color="black">
                          สรุปการใช้วงเงิน
                        </Typography>
                      </div>
                      <Card style={{ boxShadow: " 0 0 1px" }}>
                        <CardContent style={{ padding: "16px" }}>
                          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
                            <Typography variant="h4" color={AppColor["Text/Primary Text"]}>
                              วงเงินที่ใช้
                            </Typography>
                            <Typography variant="Body/32" color="black" isCurrency>
                              {props.amount} บาท
                            </Typography>
                          </div>
                          {/* <div style={{ marginTop: "8px" }} />
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography variant="h4" color="black" isCurrency>
                              คิดเป็นเงิน {props.amount} บาท
                            </Typography>
                          </div> */}
                        </CardContent>
                      </Card>
                    </div>
                  )}
                  <Button
                    onClick={
                      !isEmpty(props.checkHaveConsent) ? props.handleOpenConsentModal : props.handleClickSendRequest
                    }
                    style={{ marginBottom: 8 }}
                    width={`100%`}
                    variant="contained"
                  >
                    ยื่นคำขอ
                  </Button>
                </>
              ) : (
                <Box display="flex" marginBottom={4}>
                  {props.enableSaveDraftFeature && (
                    <Button
                      disabled={props.isUploading}
                      onClick={props.handleClickSendDraftRequest}
                      width={`100%`}
                      variant="outlined"
                      marginRight={4}
                    >
                      บันทึกร่าง
                    </Button>
                  )}
                  <Button disabled={props.isUploading} onClick={props.handleSubmit} width={`100%`} variant="contained">
                    ถัดไป
                    <CustomIconList name="ChevronRight" />
                  </Button>
                </Box>
              )}
            </>
          )}
        </ContentBox>
      </PageContent>
    )}
    {props.step === 4 && (
      <PageContent
        title={props.titlePage}
        titleCentered
        onBack={props.handleClickBack}
        extendedTitleHeight={getPageStyle(props.isHr)}
        overlapHeight={getPageStyle(props.isHr)}
        type="linear"
      >
        <SuccessPage
          isRepresent={props.isRepresent}
          requester={props.requesterFullName}
          requestName={props.title}
          type={props.type}
          amount={props.amount}
          referenceId={props.requestResponse.referenceId}
          createdDate={props.requestResponse.createdAt}
          icon={props.icon}
          iconColor={props.iconColor}
          chipTitleColor={props.chipTitleColor}
          chipBackgroundColor={props.chipBackgroundColor}
          name={props.name}
          currentUserIsHr={props.currentUserIsHr}
          hrInstead={props.hrInstead}
          disableConfirm={props.disableConfirm}
          disableConfirmRefresh={props.disableConfirmRefresh}
        />
      </PageContent>
    )}
  </>
)

const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS {
      requestConfigs {
        confirmBeforeClaim
        requests
        usage
        master
      }
    }
  `,
  GET_EMPLOYEE_REQUEST_CONFIGS: gql`
    query GET_EMPLOYEE_REQUEST_CONFIGS($employeeId: String!) {
      employeeRequestConfigs(employeeId: $employeeId) {
        usage
      }
    }
  `,
  CREATE_CLAIM_REQUEST: gql`
    mutation CREATE_CLAIM_REQUEST(
      $type: String!
      $config: JSON!
      $info: JSON!
      $employeeId: String!
      $createdBy: String!
    ) {
      createClaimRequest(
        input: { type: $type, config: $config, info: $info, employeeId: $employeeId, createdBy: $createdBy }
      ) {
        id
        referenceId
        createdAt
      }
    }
  `,
  CREATE_DRAFT_CLAIM_REQUEST: gql`
    mutation CREATE_DRAFT_CLAIM_REQUEST(
      $type: String!
      $config: JSON!
      $info: JSON!
      $employeeId: String!
      $createdBy: String!
      $diseases: [String!]
    ) {
      updateDraftClaimRequest(
        input: {
          type: $type
          config: $config
          info: $info
          employeeId: $employeeId
          createdBy: $createdBy
          diseases: $diseases
        }
      ) {
        id
        referenceId
        createdAt
      }
    }
  `,
  GET_EMPLOYEES: gql`
    query GET_EMPLOYEES {
      getEmployees {
        id
        employeeCode
        firstName
        lastName
        role
        grade
        employmentStartDate
        employmentType
        probationPeriod
        probationStatus
        functionalDesignation
      }
    }
  `,
  VALIDATE_REQUEST: gql`
    mutation VALIDATE_REQUEST($info: JSON!, $employeeId: String!, $requestName: String!) {
      validateClaim(inputs: { info: $info, employeeId: $employeeId, requestName: $requestName }) {
        hasErrorMessage
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentUser {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          employmentStartDate
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
          department
          meta
        }
      }
    }
  `,
  GET_EMPLOYEE_REQUEST_BUDGETS: gql`
    query GET_EMPLOYEE_REQUEST_BUDGETS($employeeId: String!, $childrenName: String!) {
      employeeRequestBudgets(employeeId: $employeeId, childrenName: $childrenName) {
        usage
      }
    }
  `,
  GET_EMPLOYEE_YEARLY_BUDGETS: gql`
    query GET_EMPLOYEE_YEARLY_BUDGETS($filters: JSON) {
      getEmployeeYearlyBudgets(input: { filters: $filters }) {
        id
        employeeCode
        firstName
        lastName
        budget
        dentalBudget
        year
        companyBudget
        companyDentalBudget
      }
    }
  `,
  VALIDATE_CLAIM_RANGE: gql`
    mutation VALIDATE_CLAIM_RANGE {
      validateClaimEnrollmentInRange
    }
  `,
}

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withConfirmRefresh(),
  withConfirmGoBack(),
  withHooks((props: any, hooks: any) => {
    const {
      setFieldValue,
      values,
      handleSubmit,
      currentUserInfo,
      dirty,
      disableConfirm,
      disableConfirmRefresh,
      enableConfirmRefresh,
    } = props
    const {
      useHandleSubmit,
      useState,
      useMemo,
      useMutation,
      useQuery,
      useLazyQuery,
      useCallback,
      useEffect,
      useUrlParam,
      usePrevious,
    } = hooks
    const params = useUrlParam()
    const { name: requestName } = params
    const history = useHistory()
    const [selectedRequest, setSelectedRequest] = useState()
    const [isConsentModalOpen, setIsConsentModalOpen] = useState(false)
    const [yearlyBudget, setYearlyBudget] = useState()

    const {
      data: configs,
      refetch: refetchConfig,
      loading,
    } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        const currentRequest = data.requestConfigs.requests[requestName]
        const currentUsage = data.requestConfigs.usage[requestName]

        setSelectedRequest({
          ...currentRequest,
          approvedAmount: currentUsage?.approvedAmount || 0,
          approvedCount: currentUsage?.approvedCount || 0,
        })
      },
    })

    const currentUser = useMemo(() => {
      return currentUserInfo?.employee
    }, [currentUserInfo])

    const [refetchBudget] = useLazyQuery(API.GET_EMPLOYEE_REQUEST_BUDGETS, {
      onCompleted: (data: any) => {
        const current = data.employeeRequestBudgets.usage[requestName]
        setSelectedRequest({
          ...selectedRequest,
          ...current,
        })
      },
      fetchPolicy: "network-only",
    })

    const [refetchYearlyBudget] = useLazyQuery(API.GET_EMPLOYEE_YEARLY_BUDGETS, {
      onCompleted: (data: any) => {
        const yearlyBudgetData = data.getEmployeeYearlyBudgets[0]
        setYearlyBudget(yearlyBudgetData)
      },
      fetchPolicy: "network-only",
    })

    const [refetchOnChangeRequester] = useLazyQuery(API.GET_EMPLOYEE_REQUEST_CONFIGS, {
      onCompleted: (data: any) => {
        const current = data.employeeRequestConfigs.usage[requestName]
        setSelectedRequest({
          ...selectedRequest,
          approvedAmount: current?.approvedAmount || 0,
          approvedCount: current?.approvedCount || 0,
        })
      },
      fetchPolicy: "network-only",
    })

    const [validateRequest, { loading: validateLoading }] = useMutation(API.VALIDATE_REQUEST)
    const [validateClaimRange] = useMutation(API.VALIDATE_CLAIM_RANGE)

    const master = useMemo(() => {
      return configs?.requestConfigs.master || {}
    }, [configs])

    const initialDocumentValues = useMemo(() => {
      let initialDocuments = {}
      selectedRequest?.documents?.forEach((doc: any) => {
        initialDocuments = { ...initialDocuments, [doc.name]: [] }
      })
      return initialDocuments
    }, [selectedRequest?.documents])

    const initialInfoValues: InfoValuesConfig = useMemo(
      () => ({
        inputs: {},
        documents: {},
      }),
      [],
    )

    const { data: employeesRes } = useQuery(API.GET_EMPLOYEES)

    const [createClaimRequest, { loading: createClaimRequestLoading }] = useMutation(API.CREATE_CLAIM_REQUEST, {
      refetchQueries: [{ query: API.GET_CURRENT_USER }],
    })

    const [createDraftClaimRequest, { loading: createDraftClaimRequestLoading }] = useMutation(
      API.CREATE_DRAFT_CLAIM_REQUEST,
      {
        refetchQueries: [{ query: API.GET_CURRENT_USER }],
      },
    )

    const [documentValues, setDocumentValues] = useState(initialDocumentValues)
    const [infoValues, setInfoValues] = useState(initialInfoValues)
    const [isPreview, setIsPreview] = useState(false)
    const [requester, setRequester] = useState()

    const [stepPage, setStepPage] = useState(1)
    const [requestResponse, setRequestResponse] = useState()
    const [isUploading, setIsUploading] = useState(false)

    const getStringValue = useCallback((type: INPUT_TYPE, formName: string, values: any) => {
      const value = values[formName]
      return mapStringValue(value, formName, type, values)
    }, [])

    const employeeDataDic = useMemo(() => {
      // return mapEmployeeDataDic(employeesRes)
      return keyBy(employeesRes?.getEmployees, "id")
    }, [employeesRes])

    useHandleSubmit(
      async (values: any) => {
        checkIsUploading(isUploading)

        const { initInputs, initDocuments } = prepareInitData(selectedRequest, documentValues, values, getStringValue)

        const initValues: InfoValuesConfig = {
          inputs: initInputs,
          documents: initDocuments,
        }

        const info = {
          ...selectedRequest,
          values: initValues,
          workflows: getWorkflow(selectedRequest, configs),
        }

        const isValid = await handleValidateRequest(info, requester?.id, requestName)
        setDataForValidReq(isValid, initValues, requester, setInfoValues, setRequester, setIsPreview, setStepPage)
      },
      [selectedRequest?.inputs, selectedRequest?.documents, selectedRequest, isUploading, documentValues, requester],
    )

    const onValidateError = useCallback((error: any) => {
      // @ts-ignore
      openErrorModal(error)
    }, [])

    const onValidateErrorAcademicYearMustUnique = useCallback((error: any) => {
      // @ts-ignore
      openErrorModalAcademicYearMustUnique(error)
    }, [])

    const onUnknownError = useCallback((error: any) => {
      // @ts-ignore'
      Notification.error(error.toString())
    }, [])

    const handleValidateRequest = useCallback(
      async (info: any, requesterId: string, requestName: string) => {
        return checkValidateReq(
          validateRequest,
          onValidateError,
          onValidateErrorAcademicYearMustUnique,
          info,
          requesterId,
          requestName,
          onUnknownError,
        )
      },
      [validateRequest, onValidateError, onValidateErrorAcademicYearMustUnique, onUnknownError],
    )

    const handleClickSendRequest = useCallback(async () => {
      const input = {
        selectedRequest,
        infoValues,
        configs,
        createClaimRequest,
        requestName,
        requester,
        currentUser,
        setRequestResponse,
        setStepPage,
      }
      await checkSendReq(input, onValidateError)
    }, [
      selectedRequest,
      infoValues,
      configs,
      createClaimRequest,
      requestName,
      requester,
      currentUser,
      onValidateError,
      requestResponse,
    ])

    const openModalSuccessDraft = useCallback((res: any) => {
      // @ts-ignore
      Modal.open({
        className: "SuccesDraftModal",
        children: (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Icon width="40px" height="40px" name="Warning" color={AppColor["Error/Error Text"]} />
            <Box mt="16px">
              <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                บันทึกข้อมูลสำเร็จ
              </Typography>
            </Box>
            <Box mt="8px">
              <Typography variant="body1" color={AppColor["Text/Placeholder"]}>
                ข้อมูลการขอเบิกของคุณถูกบันทึกแล้ว <br />
                คุณสามารถแก้ไขข้อมูลต่อได้ที่เมนูประวัติการเบิก <br />
                ในแถบ "แบบร่าง" เมื่อพร้อม
              </Typography>
            </Box>
          </div>
        ),
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        okButtonLabel: "กลับหน้าหลัก",
        okButtonVariant: "contained",
        onOk: () => {
          // @ts-ignore
          Modal.close()
          disableConfirm()
          paths.landingPath().push()
        },
        onCancel: () => {
          // @ts-ignore
          Modal.close()
          disableConfirm()
          paths
            .editDraftRequestPath(res?.data?.updateDraftClaimRequest.id, { tab: EnumClaimRequestStatus.DRAFT })
            .push()
        },
        disableBackdropClick: true,
      })
    }, [])

    const handleClickSendDraftRequest = useCallback(async () => {
      checkIsUploading(isUploading)

      const { initInputs, initDocuments } = prepareInitData(selectedRequest, documentValues, values, getStringValue)

      const initValues: InfoValuesConfig = {
        inputs: initInputs,
        documents: initDocuments,
      }

      const input = {
        selectedRequest,
        infoValues: initValues,
        configs,
        createDraftClaimRequest,
        requestName,
        requester,
        currentUser,
        openModalSuccessDraft,
        setRequestResponse,
        requestResponse,
      }
      await checkSendDraftReq(input, onValidateError)
    }, [
      selectedRequest,
      infoValues,
      configs,
      createDraftClaimRequest,
      requestName,
      requester,
      currentUser,
      onValidateError,
      documentValues,
      values,
    ])

    const handleFilesChange = useCallback((values: any) => {
      setDocumentValues(values)
    }, [])

    const handleClickBack = useCallback(() => {
      mapStepPage(history, stepPage, disableConfirm, setIsPreview, setStepPage)
    }, [history, stepPage, disableConfirm])

    const handleOpenConsentModal = useCallback(() => {
      setIsConsentModalOpen(true)
    }, [])

    const handleCloseConsentModal = useCallback(() => {
      setIsConsentModalOpen(false)
    }, [])

    const employeeOptions = useMemo(() => {
      return getEmployeeOptions(employeesRes, selectedRequest, currentUser)
    }, [currentUser, employeesRes, selectedRequest])

    const currentUserIsHr = useMemo(
      () => employeeIsHr(currentUser?.role) || currentUser?.meta.forceInstead,
      [currentUser],
    )

    const isRepresent = useMemo(() => {
      return requester?.id !== currentUser?.id
    }, [requester, currentUser])

    const showUploadFile = useMemo(() => {
      return isShowFile(isPreview, documentValues)
    }, [isPreview, documentValues])

    const hasIsOther = useCallback(
      (type: INPUT_TYPE, name: string) => {
        return checkIsOther(isPreview, type, name, values)
      },
      [isPreview, values],
    )

    const onValidateRangeError = useCallback((error: any) => {
      // @ts-ignore
      Modal.alert({
        className: "deleteConFirmModal",
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box justifyContent="center" mt="16px">
              <MuiIcon fontSize="large" color={AppColor["System/Info"]} name="Info"></MuiIcon>
            </Box>
            <Box justifyContent="center" mt="16px">
              <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                หมดเวลาเบิกสวัสดิการประจำปี
              </Typography>
            </Box>
            <Box justifyContent="flexStart" mt="8px" mb="24px">
              <Typography variant="body1" color="Text/Black2">
                {JSON.parse(error.message).message}
              </Typography>
            </Box>
          </Box>
        ),
        okButtonLabel: "ตกลง",
        okButtonVariant: "contained",
        buttonWidth: "100%",
      })
    }, [])

    const handleClickClaim = useCallback(async () => {
      try {
        if (!currentUserIsHr) {
          await validateClaimRange()
        }
        setStepPage(2)
      } catch (error: any) {
        const errorCode = JSON.parse(error.message).code
        if (errorCode === 801) {
          onValidateRangeError(error)
        }
      }
    }, [setStepPage])

    const handleUploading = useCallback(() => {
      setIsUploading(true)
    }, [])

    const handleUploaded = useCallback(() => {
      setIsUploading(false)
    }, [])

    const hrInstead = useMemo(() => currentUser?.id !== requester?.id, [currentUser, requester])

    const isLoaded = useMemo(() => !loading, [loading])

    useEffect(() => {
      setDocumentValues(initialDocumentValues)
    }, [initialDocumentValues])

    useEffect(() => {
      if (isEmpty(requester) && isEmpty(values.requesterId) && currentUser) {
        setFieldValue("requesterId", currentUser?.id)
      }
    }, [setFieldValue, currentUser, employeeDataDic, values.requesterId, requester])

    useEffect(() => {
      getConfigData(employeeDataDic, values?.requesterId, setRequester, refetchConfig)
    }, [values?.requesterId, refetchConfig, employeeDataDic])

    const previousRequester = usePrevious(requester)

    useEffect(() => {
      if (values.requesterId && employeeDataDic && selectedRequest) {
        const newSelected = employeeDataDic[values.requesterId]

        if (previousRequester?.id !== newSelected?.id || isEmpty(requester)) {
          setRequester(newSelected)
          setFieldValue("patient", undefined) // use in bam
          setFieldValue("children_name", undefined) // use in bam

          if (selectedRequest?.type === "ค่ารักษาพยาบาล") {
            const variables = {
              filters: {
                code: newSelected.employeeCode,
                fullName: "",
                year: new Date().getFullYear(),
                isApp: true,
              },
            }
            refetchYearlyBudget({ variables: variables })
          }

          refetchBudget({
            variables: {
              employeeId: newSelected.id,
              childrenName: values.children_name ? values.children_name["name"] : "",
            },
          })

          refetchOnChangeRequester({ variables: { employeeId: newSelected.id } })
        }
      }
    }, [
      employeeDataDic,
      previousRequester,
      refetchBudget,
      refetchOnChangeRequester,
      refetchYearlyBudget,
      requester,
      selectedRequest,
      setFieldValue,
      values.children_name,
      values.requesterId,
    ])

    useEffect(() => {
      setChildrenBirthDate(values, setFieldValue)
    }, [setFieldValue, values])

    useEffect(() => {
      if (stepPage === 1 || stepPage === 4) {
        disableConfirmRefresh()
        return
      }
      enableConfirmRefresh()
    }, [disableConfirmRefresh, enableConfirmRefresh, stepPage])

    const isPassAwayRequest = useMemo(() => {
      const requestTitle = selectedRequest?.title
      const passAwayRequest = checkIsPassaway(requestTitle)

      return passAwayRequest
    }, [selectedRequest?.title])

    const customizeRequestInput = useMemo(() => {
      return getCustomizeInput(isPassAwayRequest, selectedRequest, hrInstead)
    }, [isPassAwayRequest, selectedRequest, hrInstead])

    const currentYear = new Date().getFullYear() + 543

    const getMarginTop = useMemo(() => {
      return mapMarginTop(isPreview, hrInstead, currentUserIsHr, selectedRequest)
    }, [isPreview, hrInstead, currentUserIsHr, selectedRequest])

    const isHr = useMemo(() => checkIsHr(currentUserIsHr, hrInstead), [currentUserIsHr, hrInstead])

    const checkHaveConsent = useMemo(() => {
      let globalConfig
      let selectReuestConfig
      if (configs) globalConfig = configs?.requestConfigs?.confirmBeforeClaim
      if (selectedRequest) selectReuestConfig = selectedRequest.confirm_before_claim
      return selectReuestConfig === undefined ? globalConfig : selectReuestConfig
    }, [configs, selectedRequest])
    const closeModal = () => {
      // @ts-ignore
      Modal.close()
    }

    const modalConsentConfig = useCallback(() => {
      // @ts-ignore
      Modal.open({
        className: "ConsentModal",
        children: (
          <>
            <Header>
              <Typography className="title" variant="Body/16" color={AppColor["Primary/Primary Text"]}>
                {checkHaveConsent?.title}
              </Typography>
              <IconButton
                className="closeBtn"
                color={AppColor["Primary/Primary Text"]}
                variant="text"
                onClick={closeModal}
                style={{ padding: "0px" }}
              >
                <MuiIcon name="Close" fontSize="small" />
              </IconButton>
            </Header>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Main>
                <Typography className="title" variant="Body/14" color={AppColor["Text/Placeholder"]}>
                  {checkHaveConsent?.description}
                </Typography>
              </Main>
            </div>
          </>
        ),
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        okButtonLabel: "ยืนยัน",
        okButtonVariant: "contained",
        onOk: async ({ close, values }: any) => {
          handleClickSendRequest()
          // @ts-ignore
          Modal.close()
        },
      })
    }, [checkHaveConsent, handleClickSendRequest])

    return {
      enableSaveDraftFeature: true,
      modalConsentConfig,
      checkHaveConsent,
      titlePage: selectedRequest?.title,
      title: selectedRequest?.title || "", // display request name
      icon: selectedRequest?.icon, // mui v4 icon
      type: selectedRequest?.type, // request type
      iconColor: selectedRequest?.iconColor, // hex color code
      chipTitleColor: selectedRequest?.chipTitleColor, // hex color code
      chipBackgroundColor: selectedRequest?.chipBackgroundColor, // hex color code
      summary: selectedRequest?.summary,
      conditions: selectedRequest?.conditions || [],
      inputs: customizeRequestInput || [],
      documents: selectedRequest?.documents || [],
      remark: selectedRequest?.remark || [],
      section: selectedRequest?.section,
      name: requestName,
      isPreview,
      handleSubmit,
      handleClickSendRequest,
      handleClickSendDraftRequest,
      handleFilesChange,
      handleClickBack,
      master,
      values,
      setFieldValue,
      handleUploading,
      handleUploaded,
      isUploading,
      inputValues: infoValues.inputs || {},
      documentValues,

      employeeOptions,
      currentUserIsHr,
      requesterFullName: requester ? requester.firstName + " " + requester.lastName : undefined,
      isRepresent,
      amount: values[selectedRequest?.amountFieldName],
      showUploadFile,
      hasIsOther,
      requester,

      step: stepPage,
      approvedCount: selectedRequest?.approvedCount,
      approvedAmount: selectedRequest?.approvedAmount,
      requestResponse,
      handleClickClaim,

      hrInstead,

      disableConfirm,
      disableConfirmRefresh,
      budgetDetail: selectedRequest?.budget?.detail || null,
      budgetValues: selectedRequest?.detail || {},
      year: currentYear,
      yearlyBudget: yearlyBudget,

      isPassAwayRequest,

      isLoadingConfig: isLoaded,

      validateLoading,
      createClaimRequestLoading,
      createDraftClaimRequestLoading,
      getMarginTop,
      isHr,

      isConsentModalOpen,
      handleOpenConsentModal,
      handleCloseConsentModal,
    }
  }),
)

// const mapEmployeeDataDic = (employeesRes: any) => {
//   if (employeesRes) {
//     let dataDic: any = {}
//     employeesRes.getEmployees.forEach((employee: any) => {
//       dataDic[employee.id] = employee
//     })
//     return dataDic
//   }
//   return undefined
// }

const prepareInitData = (selectedRequest: any, documentValues: any, values: any, getStringValue: any) => {
  let initInputs = {}
  let initDocuments = {}
  selectedRequest?.inputs.forEach((input: any) => {
    initInputs = { ...initInputs, [input.name]: getStringValue(input.type, input.name, values) }
  })
  selectedRequest?.documents.forEach((document: any) => {
    initDocuments = { ...initDocuments, [document.name]: documentValues[document.name] || [] }
  })
  return { initInputs, initDocuments }
}

const getWorkflow = (selectedRequest: any, configs: any) => {
  return (isEmpty(selectedRequest.workflows) ? configs?.requestConfigs?.workflows : selectedRequest.workflows) || []
}

const setDataForValidReq = (
  isValid: any,
  initValues: any,
  requester: any,
  setInfoValues: any,
  setRequester: any,
  setIsPreview: any,
  setStepPage: any,
) => {
  if (isValid) {
    setInfoValues(initValues)
    setRequester(requester)
    setIsPreview(true)
    setStepPage(3)
  }
}

const checkIsUploading = (isUploading: any) => {
  if (isUploading) {
    return
  } // Prevent form submit when document is uploading.
}

const checkIsHr = (currentUserIsHr: any, hrInstead: any) => {
  return currentUserIsHr && hrInstead
}
const checkIsOther = (isPreview: any, type: INPUT_TYPE, name: string, values: any) => {
  if (isPreview) return false
  if (type !== INPUT_TYPE.SELECT) return false
  return values[name] === OptionValue.IS_OTHER
}

const mapStringValue = (value: any, formName: string, type: INPUT_TYPE, values: any) => {
  if (!value && value !== 0) return undefined
  switch (type) {
    case INPUT_TYPE.TIME_PICKER:
      return dayjs(value).format("HH:mm")
    case INPUT_TYPE.DATE:
      return dayjs(value).format("DD/MM/YYYY")
    case INPUT_TYPE.NUMBER:
    case INPUT_TYPE.CURRENCY:
    case INPUT_TYPE.CURRENCY_NUMBER:
    case INPUT_TYPE.SUM_AMOUNT:
      return toCurrency(value, { minimumFractionDigits: 0 })
    case INPUT_TYPE.SELECT:
      return value === OptionValue.IS_OTHER ? values[formName + "_is_other"] : values[formName]
    case INPUT_TYPE.RELATION:
    case INPUT_TYPE.CHECKBOX:
      return value
    case INPUT_TYPE.DATE_TIME:
      return dayjs(value).format("DD/MM/YYYY, HH:mm")
    default:
      return value.toString()
  }
}

const openErrorModal = (error: any) => {
  // @ts-ignore
  Modal.alert({
    className: "deleteConFirmModal",
    title: "",
    children: (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box justifyContent="center" padding={2}>
          <MuiIcon fontSize="large" color={AppColor["Warning"]} name="Warning"></MuiIcon>
        </Box>
        <Box justifyContent="center" padding={4}>
          <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
            เกิดข้อผิดพลาด
          </Typography>
        </Box>
        <Box justifyContent="flexStart" mb="16px">
          <List style={{ listStyleType: JSON.parse(error.message).length < 2 ? "none" : "inherit" }}>
            {JSON.parse(error.message).map((mes: any) => (
              <li key={1150}>
                <Typography variant="body1" color="Text/Black2">
                  {mes}
                </Typography>
              </li>
            ))}
          </List>
        </Box>
      </Box>
    ),
    okButtonLabel: "ตกลง",
    okButtonVariant: "contained",
    buttonWidth: "100%",
  })
}
const openErrorModalAcademicYearMustUnique = (error: any) => {
  const isJsonError = JSON.parse(error.message)
  // @ts-ignore
  Modal.alert({
    title: "",
    children: (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box justifyContent="center" padding={2}>
          <MuiIcon fontSize="large" color={AppColor["Warning"]} name="Warning"></MuiIcon>
        </Box>
        <Box justifyContent="center" padding={4}>
          <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
            เกิดข้อผิดพลาด
          </Typography>
        </Box>
        <Box
          style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
          padding={1}
          mb="16px"
        >
          <Box>
            <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
              {isJsonError.message1}
            </Typography>
          </Box>
          <Box>
            <Typography variant="Body/14" color={AppColor["Other/Danger"]}>
              "{isJsonError.message2}"
            </Typography>
          </Box>
          <Box>
            <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
              {isJsonError.message3}
            </Typography>
          </Box>
          <Box>
            <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
              {isJsonError.message4}
            </Typography>
          </Box>
          <Box>
            <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
              {isJsonError.message5}
            </Typography>
          </Box>
        </Box>
      </Box>
    ),
    okButtonLabel: "ตกลง",
    okButtonVariant: "contained",
    buttonWidth: "100%",
  })
}

const mapStepPage = (history: any, stepPage: any, disableConfirm: any, setIsPreview: any, setStepPage: any) => {
  switch (stepPage) {
    case 1:
      disableConfirm()
      history.goBack()
      break
    case 4:
      disableConfirm()
      paths.landingPath().push()
      break
    case 3:
      setIsPreview(false)
      setStepPage(stepPage - 1)
      break
    case 2:
      history.goBack()
      break
    default:
      setStepPage(stepPage - 1)
      break
  }
}

const getEmployeeOptions = (employeesRes: any, selectedRequest: any, currentUser: any) => {
  if (employeesRes && currentUser) {
    const employees = employeesRes.getEmployees
    if (selectedRequest?.title === "ค่าช่วยเหลือจัดการงานศพ (3 เท่า)") {
      return employees
        .filter((emp: any) => emp.employeeCode !== currentUser.employeeCode)
        .map((employee: any) => ({
          label: `${employee.firstName} ${employee.lastName}`,
          value: employee.id,
        }))
    }
    return employees.map((employee: any) => ({
      label: `${employee.firstName} ${employee.lastName}`,
      value: employee.id,
    }))
  }
  return []
}

const setupFieldValue = (currentUser: any, employeeDataDic: any, setFieldValue: any) => {
  if (currentUser && employeeDataDic) {
    setFieldValue("requesterId", currentUser.id)
    setFieldValue("patient", undefined) // use in bam
    setFieldValue("children_name", undefined) // use in bam
  }
}

const checkValidateReq = async (
  validateRequest: any,
  onValidateError: any,
  onValidateErrorAcademicYearMustUnique: any,
  info: any,
  requesterId: any,
  requestName: any,
  onUnknownError: any,
) => {
  try {
    const res = await validateRequest({
      variables: { info, employeeId: requesterId, requestName },
    })
    const hasError = !!get(res, "errors", false)
    console.log({ hasError })

    if (res.errors) throw new Error("เกิดข้อผิดพลาดบางอย่าง")
  } catch (error: any) {
    if (isJson(error.message)) {
      const jsonError = JSON.parse(error.message)
      if (jsonError["code"] === 567) {
        onValidateErrorAcademicYearMustUnique(error)
      } else {
        onValidateError(error)
      }
    } else {
      onUnknownError(error)
    }
    return false
  }
  return true
}

const checkSendReq = async (input: any, onValidateError: any) => {
  try {
    const {
      selectedRequest,
      infoValues,
      configs,
      createClaimRequest,
      requestName,
      requester,
      currentUser,
      setRequestResponse,
      setStepPage,
    } = input
    const info = {
      ...selectedRequest,
      values: infoValues,
      workflows:
        (isEmpty(selectedRequest.workflows) ? configs?.requestConfigs?.workflows : selectedRequest.workflows) || [],
    }
    const res = await createClaimRequest({
      variables: {
        type: requestName,
        config: selectedRequest,
        info,
        employeeId: requester?.id,
        createdBy: currentUser.id,
      },
    })
    setRequestResponse(res.data.createClaimRequest)
    setStepPage(4)
  } catch (error: any) {
    if (isJson(error.message)) {
      onValidateError(error)
    }
  }
}

const checkSendDraftReq = async (input: any, onValidateError: any) => {
  try {
    const {
      selectedRequest,
      infoValues,
      configs,
      createDraftClaimRequest,
      requestName,
      requester,
      currentUser,
      diseases,
      openModalSuccessDraft,
      setRequestResponse,
    } = input
    const info = {
      ...selectedRequest,
      values: infoValues,
      workflows:
        (isEmpty(selectedRequest.workflows) ? configs?.requestConfigs?.workflows : selectedRequest.workflows) || [],
    }
    const res = await createDraftClaimRequest({
      variables: {
        type: requestName,
        config: selectedRequest,
        info,
        employeeId: requester?.id,
        createdBy: currentUser.id,
        diseases,
      },
    })
    setRequestResponse(res)
    openModalSuccessDraft(res)
  } catch (error: any) {
    if (isJson(error.message)) {
      onValidateError(error)
    }
  }
}

const isShowFile = (isPreview: any, documentValues: any) => {
  if (isPreview) return !every(documentValues, (value) => isEmpty(value))
  return true
}

const getConfigData = async (employeeDataDic: any, requesterId: string, setRequester: any, refetchConfig: any) => {
  if (employeeDataDic) {
    // const selectedId = values.requesterId
    const selected = employeeDataDic[requesterId]

    if (selected) {
      // setRequester(selected)
      await refetchConfig({
        variables: { employeeId: selected.id },
      })
    }
  }
}

const getBudgetData = async (
  employeeDataDic: any,
  // values: any,
  requesterId: string,
  selectedRequest: any,
  setRequester: any,
  refetchYearlyBudget: any,
  refetchBudget: any,
  childrenName?: { name: string },
) => {
  // call(async () => {
  if (employeeDataDic) {
    const selectedId = requesterId
    const selected = employeeDataDic[selectedId]

    if (selected) {
      if (selectedRequest?.type === "ค่ารักษาพยาบาล") {
        const variables = {
          filters: {
            code: selected.employeeCode,
            fullName: "",
            year: new Date().getFullYear(),
            isApp: true,
          },
        }

        await refetchYearlyBudget({ variables: variables })
      }

      await refetchBudget({
        variables: {
          employeeId: selected.id,
          childrenName: childrenName ? childrenName["name"] : "",
        },
      })
    }
  }
  // })
}

const setChildrenBirthDate = (values: any, setFieldValue: any) => {
  if (!values.userDependent) {
    setFieldValue("children_birthdate", "")
  } else if (values.children_name) {
    setFieldValue("children_birthdate", values.children_name.birthDate)
  }
}

const checkIsPassaway = (requestTitle: any) => {
  return (
    requestTitle === "ค่าช่วยเหลือจัดการงานศพ (3 เท่า)" ||
    requestTitle === "ค่าเจ้าภาพงานศพ" ||
    requestTitle === "ค่าอุปกรณ์เคารพศพ"
  )
}

const mapMarginTop = (isPreview: any, hrInstead: any, currentUserIsHr: any, selectedRequest: any) => {
  if (isPreview) {
    if (hrInstead && currentUserIsHr && selectedRequest?.remark?.length === 0) {
      return "16px"
    } else if (selectedRequest?.remark?.length === 0) {
      return "16px"
    } else {
      return "0px"
    }
  } else {
    return "8px"
  }
}

const getCustomizeInput = (isPassAwayRequest: any, selectedRequest: any, hrInstead: any) => {
  if (isPassAwayRequest) {
    const newSelectedRequest = selectedRequest?.inputs.map((input: any) => {
      const newOptions = input.options || []
      return {
        ...input,
        options: hrInstead ? newOptions : newOptions.filter((option: any) => option !== "self"),
      }
    })

    return newSelectedRequest
  }

  return selectedRequest?.inputs
}

const getPageStyle = (isHr: any) => {
  return isHr ? "370px" : "300px"
}

export default enhancer(ClaimDetailPage)
