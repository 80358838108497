import { Box, Icon, Typography } from "components"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { EnumEmployeeRole } from "constants/enums/employee-role"
import { useCallback, useMemo } from "react"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

const StatusItem = styled("div")<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  padding: 12px 0px;
  border: ${(props) => (props.selected ? "1px solid dark" : "0px")};
  color: ${(props) => (props.selected ? AppColor["Primary/Primary Text"] : "#CBD0D1")};
  border-bottom: 1px solid ${(props) => (props.selected ? AppColor["Primary/Primary Text"] : "#A2AAAD")};
  box-sizing: border-box;
  cursor: pointer;
`

const StatusBox = styled(Box)`
  display: flex;
`

const CustomIconList = styled(Icon)`
  font-size: 16px;
  margin-right: 2px;
`

interface StatusTabProps {
  onClick?: (status: EnumClaimRequestStatus) => void
  selectedStatus?: EnumClaimRequestStatus
  hasCancel?: boolean
  isHistoryPage?: boolean
  role?: EnumEmployeeRole
}

export const StatusTab = (props: StatusTabProps) => {
  const { selectedStatus, onClick, role, hasCancel = true, isHistoryPage = false } = props
  const handleClick = useCallback(
    (status: EnumClaimRequestStatus) => {
      if (onClick) onClick(status)
    },
    [onClick],
  )

  const employeeIsHr = useMemo(() => role === EnumEmployeeRole.HR, [role])

  return (
    <StatusBox>
      {isHistoryPage && (
        <StatusItem
          onClick={() => handleClick(EnumClaimRequestStatus.DRAFT)}
          border-right
          selected={selectedStatus === EnumClaimRequestStatus.DRAFT}
        >
          {/* <CustomIconList name={env.ICON_CONFIG.WAITING_STATUS as IconName} /> */}
          <Typography variant="subtitle1">แบบร่าง</Typography>
        </StatusItem>
      )}
      <StatusItem
        onClick={() => handleClick(EnumClaimRequestStatus.WAITING)}
        border-right
        selected={selectedStatus === EnumClaimRequestStatus.WAITING}
      >
        {/* <CustomIconList name={env.ICON_CONFIG.WAITING_STATUS as IconName} /> */}
        <Typography variant="subtitle1">รออนุมัติ</Typography>
      </StatusItem>
      {(employeeIsHr || isHistoryPage) && (
        <StatusItem
          onClick={() => handleClick(EnumClaimRequestStatus.WAITING_PAYMENT_CONFIRM)}
          border-right
          selected={selectedStatus === EnumClaimRequestStatus.WAITING_PAYMENT_CONFIRM}
        >
          {/* <CustomIconList name={env.ICON_CONFIG.WAITING_STATUS as IconName} /> */}
          <Typography variant="subtitle1">รอวันชำระเงิน</Typography>
        </StatusItem>
      )}
      <StatusItem
        onClick={() => handleClick(EnumClaimRequestStatus.APPROVED)}
        border-right
        selected={selectedStatus === EnumClaimRequestStatus.APPROVED}
      >
        {/* <CustomIconList name="CheckCircle" /> */}
        <Typography variant="subtitle1">อนุมัติ</Typography>
      </StatusItem>
      <StatusItem
        onClick={() => handleClick(EnumClaimRequestStatus.REJECTED)}
        border-right
        selected={selectedStatus === EnumClaimRequestStatus.REJECTED}
      >
        {/* <CustomIconList name="Cancel" /> */}
        <Typography variant="subtitle1">ไม่อนุมัติ</Typography>
      </StatusItem>
      {hasCancel && (
        <StatusItem
          onClick={() => handleClick(EnumClaimRequestStatus.CANCELED)}
          border-right={false}
          selected={selectedStatus === EnumClaimRequestStatus.CANCELED}
        >
          {/* <CustomIconList name={env.ICON_CONFIG.CANCEL_STATUS as IconName} /> */}
          <Typography variant="subtitle1">ยกเลิก</Typography>
        </StatusItem>
      )}
    </StatusBox>
  )
}
